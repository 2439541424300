<template>
  <!-- eslint-disable -->
  <!--Main Wrapper Start-->
  <div id="top" class="wrapper">
    <!-- Content Section Start -->
    <div class="midd-container">
      <!-- Hero Section Start -->
      <div class="hero-main mercury-layout white-sec" :style="style">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-sm-12 col-md-12 mobile-center d-flex flex-column align-items-center">
              <h1 class="text-center">JOIN THE AK-LABS ECOSYSTEM NOW!</h1>
              <p class="lead text-center">Secure your fair share of success. Explore various opportunities to increase your gains<br> and acquire a portion of our shared accomplishments attained here at AK-LABS.</p>
              <div class="hero-btns">
                <a href="https://pancakeswap.finance/swap?outputCurrency=0xd520b7de0d39000ac5ff34a31fe10e8f9bd0944a" target="_blank" class="btn el-btn mr-md-2 mb-md-2 mb-md-0">Buy Now</a>
                <a href="https://mint.ak-labs.io/" target="_blank" class="btn el-btn mr-sm-2 mr-md-2 mb-md-2 mb-md-0">Mint</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Hero Section End -->
      <div>
        <!--About Start -->
        <div id="about" class="about-section style-2 p-tb">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="sec-title"><h1>ABOUT INKIES</h1></div>
                <p class="lead">Here at AK-LABS, innovation meets opportunity. Our approach was designed to generate growth opportunities for both us and our stakeholders, ensuring they share an equal part of our success. Join us on our journey to unlock exclusive opportunities, shared rewards and sustainable growth.</p>
                <!-- <a class="btn el-btn" href="https://ak-labs.gitbook.io/whitepaper" target="_blank">Read More</a> -->
              </div>
              <div class="col-md-6 text-center">
                <img :src="inkie" />
              </div>
            </div>
          </div>
        </div>
        <!--About end -->
        <div id="benefits" class="dark c-l p-tb white-sec">
          <div class="container">
            <div class="row">
              <div class="col-md-6 text-center">
                <img :src="inkie2" />
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="sec-title"><h1>Exclusive Benefits</h1></div>
                <p class="white">The AK-LABS Genesis NFT is your passport to the AK-LABS ecosystem and therefore to a wide range of benefits, including:</p>
                <ul class="benefit-container">
                  <li><p><span>&#10003;</span> Early access whitelist opportunities</p></li>
                  <li><p><span>&#10003;</span> Buy alerts for promising projects</p></li>
                  <li><p><span>&#10003;</span> Tier 1 projects VC presale allocations</p></li>
                  <li><p><span>&#10003;</span> Launchpad allocations for new token launches</p></li>
                  <li><p><span>&#10003;</span> Share of the Alt POT-folio for Genesis NFT holders</p></li>
                </ul>
                <a href="https://mint.ak-labs.io/" target="_blank" class="btn el-btn mr-sm-2 mr-md-2 mb-md-2 mb-md-0 mt-6">Mint NOW</a>
                <!-- <a class="btn el-btn" href="https://ak-labs.gitbook.io/whitepaper" target="_blank">Read More</a> -->
              </div>
            </div>
          </div>
          </div>
        </div>
        <!-- Benefits Start -->
        <div class="benefits p-tb white-sec">
          <div class="container">
            <div class="sec-title text-center"><h3>Revenue-sharing Model</h3></div>
            <div class="sub-txt text-center">
              <p>Our unique revenue-sharing model will help you gather income from diverse streams:</p>
            </div>
            <div class="benefits-box row">
              <div class="col-md-3">
                <div class="bf-icon">
                  <img :src="minting" alt="">
                </div>
                <h3>NFT Minting</h3>
              </div>
              <div class="col-md-3">
                <div class="bf-icon">
                  <img :src="vc" alt="">
                </div>
                <h3>VC Raises</h3>
              </div>
              <div class="col-md-3">
                <div class="bf-icon">
                  <img :src="marketplace" alt="Treasury Icon">
                </div>
                <h3>Marketplace Fees</h3>
              </div>
              <div class="col-md-3">
                <div class="bf-icon">
                  <img :src="token" alt="Treasury Icon">
                </div>
                <h3>Token Taxes</h3>
              </div>
            </div>
          </div>
        </div>
        <!-- Benefits End -->
        <!--About Start -->
        <div id="token" class="about-section p-tb">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-12 mt-12">
                <div class="sec-title"><h1>$iNKiES Token: Unlocking Further Potential</h1></div>
                <p class="lead">In March 2024 our ecosystem was enhanced by the launch of $iNKiES token, designed specifically to elevate and fortify the AK-LABS ecosystem as well as increase the benefits for our community members. Participants who hold 7,500 iNKiES tokens have access to our exclusive Venture Capital (VC) Allocations.<br></p>
                <div class="sub-txt text-center">
                </div>
                <div class="row sale-boxes p-b">
                  <a href="https://pancakeswap.finance/swap?outputCurrency=0xd520b7de0d39000ac5ff34a31fe10e8f9bd0944a" target="_blank" class="btn el-btn mr-md-2 mb-md-2 mb-md-0 mt-6">Buy Now</a>
                </div>
              </div>
            </div>
          </div>
        <!--About end -->
        <!--About end -->
        <div id="community" class="dark c-l p-tb white-sec">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="sec-title"><h1>AK-LABS Mission: Empowering our Community.</h1></div>
                <p class="white">At AK-LABS, our mission is crystal clear: we prioritize rewarding our holders, crypto enthusiasts who align with and support our vision, over individual victories. To achieve this, we reinvest all generated earnings into a diversified and promising POT-FOLIO, thus providing our NFTs with real, tangible value. Whether it's revenue sharing, NFT mint funds, VC allocations, launchpad allocations, token taxes, or marketplace fees, our community can trust that their investments are being utilized effectively.</p>

                <a class="btn el-btn mt-6" href="https://t.me/AKLABSofficialTG" target="_blank">Join Us!</a>
              </div>
              <div class="col-md-6 text-center">
                <img :src="portal" />
              </div>
            </div>
          </div>
          </div>
        </div>
        <!-- Benefits Start -->
        <!-- Team sec start-->
        <div id="team" class="team-section p-tb">
          <div class="container">
            <div class="team-member">
              <div class="sec-title"><h3>Executive Team</h3></div>
              <div>
                <p>Our executive team consists of specialized individuals from the cryptocurrency world, with extensive knowledge in navigating the intricacies of the WEB 3 landscape. Guided by the leadership of AK, a seasoned veteran with many years of experience in navigating this continuously evolving landscape, and driven by a desire for community welfare, our objective is to provide and streamline access to profitable opportunities in the crypto market while delivering substantial value to our community.</p>
              </div>
              <div class="row">
                <div class="col wow fadeInUp">
                  <div class="team d-flex align-items-center flex-column">
                    <img :src="ak" alt="Ak">
                    <h4>@AK_LABS_iNKiEs</h4>
                    <span>Founder</span>
                  </div>
                </div>
                <div class="col wow fadeInUp" data-wow-delay="0.1s">
                  <div class="team d-flex align-items-center flex-column">
                    <img :src="tigran" alt="Tigran">
                    <h4>@TigranA8</h4>
                    <span>Co-Founder</span>
                  </div>
                </div>
                <div class="col wow fadeInUp" data-wow-delay="0.1s">
                  <div class="team d-flex align-items-center flex-column">
                    <img :src="cgceo" alt="CG">
                    <h4>@CryptoGemContact</h4>
                    <span>Head of Development</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Team sec ends -->
        <!-- The Roadmap  start-->
        <div v-show="false" id="roadmap" class="roadmap-sec p-tb white-sec">
          <div class="container">
            <div class="sec-title text-center"><h3>Roadmap</h3></div>
            <div class="roadmap-slider owl-carousel">
              <div class="item">
                <span>Website Redesign</span>
                <p>Intended deliver you a refreshed interface and a glimpse into our vision.</p>
              </div>
              <div class="item">
                <span>White Paper</span>
                <p>Bringing you comprehensive insights into our recent advancements and plans for the future.</p>
              </div>
              <div class="item">
                <span>Dapp</span>
                <p>Prepare for the reveal of our venture capital website and decentralized application.</p>
              </div>
              <div class="item">
                <span>2023 - Q1</span>
                <p>Seek for partners</p>
                <p>Organisation of more events</p>
                <p>Hold the first funding round</p>
                <p>Project becomes public</p>
              </div>
            </div>
          </div>
        </div>
        <!-- The Roadmap end-->
        <!-- Brand logo slider -->
        <div v-show="false" id="partners" class="brand-logo-slider c-l p-tb white-sec">
          <div class="container">
            <div class="text-center"><h2 class="section-heading">Our <span>Partners</span></h2></div>
            <carousel
              :autoplay="true"
              :nav="false"
              :dots="false"
              :items="4"
              :responsive="{0:{items:1,nav:false,center:true},600:{items:4,nav:true}}"
              class="partners"
            >
              <div class="item"><a href="https://t.me/CCryptoGemsOfficial" target="_blank"><img :src="cg" alt="Crypto Gems" /></a></div>
              <div class="item"><a href="https://t.me/+20xbyG1vjp9lMjlk" target="_blank"><img :src="theplace" alt="The Place" /></a></div>
              <div class="item"><a href="https://t.me/fairtokencake" target="_blank"><img :src="frtc" alt="FairToken" /></a></div>
              <div class="item"><a href="https://carmel.io" target="_blank"><img :src="carmel" alt="Carmel" /></a></div>
            </carousel>
          </div>
        </div>
        <!-- Brand logo end -->
        <!-- Brand logo slider -->
        <div id="partners" class="brand-logo-slider c-l p-tb white-sec">
          <div class="container">
            <div class="text-center"><h2 class="section-heading">Our <span>Partners</span></h2></div>
            <carousel
              :autoplay="true"
              :nav="false"
              :dots="false"
              :items="4"
              :responsive="{0:{items:1,nav:false,center:true},600:{items:4,nav:true}}"
              class="partners"
            >
              <div class="item"><a href="https://t.me/CCryptoGemsOfficial" target="_blank"><img width="150" :src="cg" alt="Crypto Gems" /></a></div>
              <div class="item"><a href="https://t.me/ElrosCrypto" target="_blank"><img width="150" :src="elros" alt="Elros" /></a></div>
              <div class="item"><a href="https://t.me/https://t.me/TheDegenFactory" target="_blank"><img width="150" :src="degen" alt="Degen Factory" /></a></div>
              <div class="item"><a href="https://t.me/TheDegenFactory" target="_blank"><img width="150" :src="apetopia" alt="Apetopia" /></a></div>
            </carousel>
          </div>
        </div>
        <!-- Brand logo end -->
      </div>
    </div>
    <!-- Content Section End -->
  </div>
  <!--Main Wrapper End-->
</template>

<script>
  import { WOW } from 'wowjs';
  import Vue from 'vue';
  import { mapGetters } from 'vuex';
  import carousel from 'vue-owl-carousel';
  import logo from '@/assets/png/logo.png';
  import token from '@/assets/gif/token.gif';
  import marketplace from '@/assets/gif/marketplace.gif';
  import minting from '@/assets/gif/nft.gif';
  import vc from '@/assets/gif/vc.gif';
  import backgroundBanner from '@/assets/gif/bgvid.gif';
  import brandLogo1 from '@/assets/png/brand-logo1.png';
  import brandLogo2 from '@/assets/png/brand-logo2.png';
  import brandLogo3 from '@/assets/png/brand-logo3.png';
  import brandLogo4 from '@/assets/png/brand-logo4.png';
  import brandLogo5 from '@/assets/png/brand-logo5.png';
  import inkie from '@/assets/gif/inkie1.gif';
  import inkie2 from '@/assets/gif/inkie2.gif';
  import portal from '@/assets/gif/portal.gif';
  import cg from '@/assets/png/cg.png';
  import apetopia from '@/assets/png/apetopia.png';
  import degen from '@/assets/png/degen.png';
  import elros from '@/assets/png/elros.png';
  import ak from '@/assets/png/akpf.png';
  import cgceo from '@/assets/png/cgceo.png';
  import tigran from '@/assets/png/tigranpf.png';

  export default Vue.extend({
    name: 'elros-home',
    components: { carousel },
    data: () => ({
      logo,
      token,
      inkie,
      inkie2,
      portal,
      minting,
      cg,
      cgceo,
      tigran,
      ak,
      apetopia,
      elros,
      degen,
      vc,
      marketplace,
      brandLogo1,
      brandLogo2,
      brandLogo3,
      brandLogo4,
      brandLogo5,
      style: { backgroundImage: `url(${backgroundBanner})` },
    }),
    computed: {
      ...mapGetters({
        isMobileView: 'common/isMobileView',
      }),
    },
    mounted() {
      window.initRoadmapCarousel();
      /* eslint-disable */
      const wow = new WOW({
        live: false,
      });
      wow.init();
    },
  });
</script>

<style scoped>
.partners .item {
  align-items: center;
  display: flex;
  justify-content: center;
}

.dark {
  background: #0f0f0f;
}

.white {
  color: #ffffff;
}

.benefit-container {
  color: white;
  list-style: none;
}

.benefit-container li span {
  color: #48e8fe;
}
</style>
